import React, { Component, Fragment, useEffect, useState } from 'react';
import Datas from '../../../data/course/item.json';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Pagination from './../../../components/Pagination';
import DOMPurify from 'dompurify';
import { PostApi } from '../../../services/CommonService2';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import HeaderTwo from '../../../components/HeaderTwo';
import { Styles } from '../../events/styles/event';
import CourseCategory from './CourseCategory';
import PopularCourse from './PopularCourse';
import ProgramCourseSearch from './ProgramCourseSearch';
import FooterTwo from '../../../components/FooterTwo';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Header3 from '../../../home3components/Header3';
import Footer3 from '../../../home3components/Footer3';

const CourseItemGrid = (props) => {
    const [searchData, setSearchData] = useState([])
    const data = useLocation();
    const history = useHistory();
    const [courseData, setCourseData] = useState([])
    const [page, setPage] = useState(1)
    const [tenantId,setTenantId] = useState('')

    useEffect(() => {
        getCourses()
    }, [searchData])


    const [course, setCourse] = useState([])
    const [courselist, setCourselist] = useState([])
    const [filter, setFilter] = useState([])
    const [isLoadMore, setisLoadMore] = useState(true)

    const [total, setTotal] = useState(0);
    const [reqparam, setreqparam] = useState([])
    const [currentPage, setCurrentPage] = useState(1);


    const getCourses = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        setTenantId(tntId)
        localStorage.removeItem('edutype');
        localStorage.setItem('edutype', JSON.stringify('ITPROGRAMING'));
        var reqparam = ''
        if (data.state.programid) {

            if (searchData == '' || searchData == undefined) {
                reqparam = {
                    pageno: '-1',

                    query: { status: 'Active', program: { $elemMatch: { "value": data.state.programid } } },
                    completion: 'Y',
                    userid: ''
                }

            }
            else {
                reqparam = {
                    pageno: '-1',
                    query: { status: 'Active', program: { $elemMatch: { "value": data.state.programid } }, name: { $regex: searchData, $options: "i" } },
                    completion: 'Y',
                    userid: ''
                }



            }

        }
        else {
            if (searchData == '' || searchData == undefined) {
                reqparam = {
                    pageno: '-1',
                    page:currentPage,
                    query: { status: 'Active', program: { $elemMatch: { "value": data.state.id } } },
                    completion: 'Y',
                    userid: ''
                }

            }
            else {
                reqparam = {
                    pageno: '-1',
                    query: { status: 'Active', program: { $elemMatch: { "value": data.state.id } }, name: { $regex: searchData, $options: "i" } },
                    completion: 'Y',
                    userid: ''
                }
            }
        }
       
        var rawData = []
        var ResponseData = {}
        reqparam.page = 1
        do {

            ResponseData = await PostApi(reqparam, "PROGRAMCOURSES");
            // console.log("Respnseee",ResponseData.data);
            reqparam.page += 1
            rawData.push(...ResponseData.data)
        } while (ResponseData.data.length != 0);

        setTotal(rawData.length)

        reqparam.page = undefined

        ResponseData = await PostApi(reqparam, "PROGRAMCOURSES");
        setCourse(ResponseData.data);


    }
    
   

    useEffect(() => {
        const newData = async () => {
            var reqparam = ''
            if (data.state.programid) {

                if (searchData == '' || searchData == undefined) {
                    reqparam = {
                        pageno: '-1',
                        page: currentPage,
                        query: { status: 'Active', program: { $elemMatch: { "value": data.state.programid } } },
                        completion: 'Y',
                        userid: ''
                    }

                }
                else {
                    reqparam = {
                        pageno: '-1',
                        page: currentPage,
                        query: { status: 'Active', program: { $elemMatch: { "value": data.state.programid } }, name: { $regex: searchData, $options: "i" } },
                        completion: 'Y',
                        userid: ''
                    }



                }

            }
            else {
                if (searchData == '' || searchData == undefined) {
                    reqparam = {
                        pageno: '-1',
                        page: currentPage,
                        query: { status: 'Active', program: { $elemMatch: { "value": data.state.id } } },
                        completion: 'Y',
                        userid: ''
                    }

                }
                else {
                    reqparam = {
                        pageno: '-1',
                        page: currentPage,
                        query: { status: 'Active', program: { $elemMatch: { "value": data.state.id } }, name: { $regex: searchData, $options: "i" } },
                        completion: 'Y',
                        userid: ''
                    }
                }
            }
            const ResponseData = await PostApi(reqparam, "PROGRAMCOURSES");
            // console.log(" Courses Listtt", ResponseData.data);
            // console.log("total", ResponseData.data.length);
            setCourse(ResponseData.data);


        }
        newData()
    }, [currentPage])



    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const npage = Math.ceil(total / recordsPerPage)
    const records = course.slice(firstIndex, lastIndex)
    const numbers = [...Array(npage + 1).keys()].slice(1)


    const prePage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }


    }
    const changeCPage = (id) => {
        setCurrentPage(id)

    }
    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }

    }
    const goBack = () => {
        history.goBack();
    };

    return (
        <Styles>
            <button className="btn btn-primary" onClick={goBack} style={{ position: "fixed", bottom: "20px", left: "10px", zIndex: 999 }}><i class="fa-solid fa-arrow-left"></i></button>


            <div className="main-wrapper event-page">
            {tenantId=="ffe21ec9" ? <Header3/> : <HeaderTwo/>}

                <section className="event-page-area">
                    <Container>
                        <Row>
                            <Col lg="9" md="12">
                                <div className="event-title">
                                    <h3>{data?.state.name}</h3>
                                </div>
                                <div className="event-desc desc1">
                                    <p>{data?.state.description}</p>
                                </div>


                                {course.length>0 ?
                                    course?.map((data, i) => {
                                        let imageid = 'https://visualpathedu.com/static/media/4th-year.15b862d606512e3257c0.jpg';
                                        if (data?.image) {
                                            imageid = data.image;
                                        } else if (data?.image_url) {
                                            imageid = data.image_url;
                                        }
                                        else {
                                            imageid = data.imageurl ? data.imageurl : "";
                                        }

                                        return <div className="event-box" key={i}>
                                            <Row>
                                                <Col xl="3" lg="4" md="0">
                                                    <div className="event-img">
                                                        {/* <img src={data.image} className='img1' ></img> */}
                                                        {/* {data?.image && <img src={data.image} className='img1'></img>} */}
                                                        <img src={imageid} className='img1'></img>


                                                    </div>
                                                </Col>
                                                <Col xl="8" lg="7" md="12">
                                                    <div className="event-content ">
                                                        <div className="content-box">
                                                            <Row>
                                                                <Col md="9">
                                                                    {data.iscompleted &&
                                                                        <div className="event-title">
                                                                            <h6>{data?.name} <span><i class="fa-solid fa-square-check" style={{ color: "#11B67A" }}></i></span></h6>
                                                                        </div>

                                                                    }
                                                                    {!data.iscompleted &&
                                                                        <div className="event-title">
                                                                            <h6>{data?.name} </h6>
                                                                        </div>

                                                                    }

                                                                    <div className="event-time-location">

                                                                    </div>
                                                                    <div className="event-desc">
                                                                        <p>{data?.description.slice(0, 100)}</p>
                                                                    </div>
                                                                </Col>
                                                                <Col md="3" className="text-center">

                                                                    <div className="join-btn btn2">
                                                                        <Link to={{ pathname: "/lmspage2", state: data }}>Click Here</Link>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    }): <Skeleton className='mb-3' height={150} count={4} /> 
                                }
                                <Col md="12" className="text-center">
                                    {/* <Pagination /> */}

                                    <div className='mainpagination'>
                                        <ul className='pagination'>
                                            <li className='page-item'>
                                                <label className=' btn btn-outline-success page-link' onClick={prePage}><i class="fa-solid fa-angles-left"></i></label>

                                            </li>
                                            {numbers.map((n, i) => (
                                                <li className={`page-item ${currentPage === n ? 'active' : ""}`} key={i}>
                                                    <label className='btn btn-outline-success page-link' onClick={() => changeCPage(n)}>{n}</label>

                                                </li>

                                            ))}

                                            <li className='page-item'>
                                                <label className='btn btn-outline-success page-link' onClick={nextPage}><i class="fa-solid fa-angles-right"></i></label>

                                            </li>


                                        </ul>
                                    </div>


                                </Col>
                            </Col>

                            <Col lg="3" md="0">
                                <Row>
                                    <ProgramCourseSearch setSearchData={setSearchData} />
                                </Row>
                                <Row>
                                    {/* <CourseCategory /> */}

                                </Row>
                                <Row>
                                    <PopularCourse />

                                </Row>

                            </Col>


                        </Row>

                    </Container>
                </section>
                {tenantId=="ffe21ec9" ? <Footer3 /> :   <FooterTwo />}




            </div>

        </Styles>

    )

}
export default CourseItemGrid


